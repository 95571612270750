import React, { useState, useEffect, useRef } from 'react';
import { ParsedSaunaProducts } from '.';
import CtaLink from '../Global/CtaLink';
//@ts-ignore
import * as styles from './what-sauna-results.module.css';
//@ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import ArrowLgButton from '../Global/ArrowLgButton';
import { BuildLinkHref } from '../Global/BuildLinkHref';
import { getMultiChoice } from '../Global/DataUtils';
import {
    CTALocation,
    KontentRadioMultiChoice,
    KontentStringValue,
} from '../../../types/KontentProps';

interface Props {
    results?: ParsedSaunaProducts[];
    results_headline?: string;
    show_2nd_cta?: KontentRadioMultiChoice<'yes' | 'no'>;
    cta_configuration__cta_label?: KontentStringValue;
    cta_configuration__cta_location?: CTALocation;
    cta_configuration__cta_window_action?: {
        value: {
            codename: 'same_window' | 'new_window';
        }[];
    };
    cta_configuration__cta_location_anchor_point?: {
        value: string;
    };
}

const WhatSaunaResults: React.FC<Props> = ({
    results,
    results_headline,
    ...props
}) => {
    const showGetPricing =
        getMultiChoice(props.show_2nd_cta) === 'yes' ? true : false;
    const getPricingCtaTarget =
        getMultiChoice(props.cta_configuration__cta_window_action) ===
        'new_window'
            ? '_new'
            : '_self';

    if (!results) return null;
    const resultSectionEl = useRef(null);

    const breakpoints = useBreakpoint();
    let currentDisplayNumber: number;

    switch (true) {
        case breakpoints.l:
            currentDisplayNumber = 3;
            break;
        case breakpoints.md:
            currentDisplayNumber = 2;
            break;
        default:
            currentDisplayNumber = results.length;
    }

    const [currentResult, setCurrentResult] = useState(0);
    const [visibleResults, setVisibleResults] = useState(
        results?.slice(currentResult, currentDisplayNumber)
    );

    useEffect(() => {
        setVisibleResults(results?.slice(currentResult, currentDisplayNumber));
        if (resultSectionEl?.current) {
            resultSectionEl.current.scrollIntoView();
            if (breakpoints.md) {
                window && window.scrollBy(0, -96);
            } else {
                window && window.scrollBy(0, -1780);
            }
        }
    }, [currentDisplayNumber]);

    const handleClickPrev = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        const newResult = currentResult - 1;
        setVisibleResults(
            results?.slice(newResult, newResult + currentDisplayNumber)
        );
        setCurrentResult(newResult);
    };

    const handleClickNext = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        const newResult = currentResult + 1;
        setVisibleResults(
            results?.slice(newResult, newResult + currentDisplayNumber)
        );
        setCurrentResult(newResult);
    };

    const renderProduct = (result: ParsedSaunaProducts) => {
        return (
            <div key={result.slug} className={styles.result}>
                <div className={styles.resultImage}>
                    <img
                        src={result?.image?.url}
                        alt={result?.image?.description}
                    />
                </div>
                <div className={styles.resultContent}>
                    <h4>{result.name}</h4>
                    <p>{result.description}</p>
                    <h5>HEALTH BENEFITS:</h5>
                    <p>
                        {result?.wellnessTags?.length &&
                            result?.wellnessTags?.map((tag, count) => (
                                <span key={`${result.slug}${tag?.name}`}>
                                    {tag?.name}
                                    {count === result?.wellnessTags?.length - 1
                                        ? ''
                                        : ', '}
                                </span>
                            ))}
                    </p>
                    <h5>CAPACITY:</h5>
                    <p>{result?.capacity?.name}</p>
                </div>
                {result.slug && (
                    <div className={styles.cta}>
                        <CtaLink
                            id={'inline_product_quiz_results_button'}
                            href={result.slug}
                        >
                            GO TO PRODUCT
                        </CtaLink>
                    </div>
                )}
                <div className={styles.cta}>
                    {showGetPricing && (
                        <CtaLink
                            id={'inline_pricing_quiz_results_button'}
                            href={
                                props
                                    .cta_configuration__cta_location_anchor_point
                                    ?.value
                                    ? BuildLinkHref(
                                          props.cta_configuration__cta_location
                                      ) +
                                      '#' +
                                      props
                                          .cta_configuration__cta_location_anchor_point
                                          ?.value
                                    : BuildLinkHref(
                                          props.cta_configuration__cta_location
                                      )
                            }
                            target={getPricingCtaTarget}
                        >
                            {props.cta_configuration__cta_label?.value}
                        </CtaLink>
                    )}
                </div>
            </div>
        );
    };

    return (
        <section className={styles.section} ref={resultSectionEl}>
            <div className={styles.resultsWrapper}>
                <h3>{results_headline}</h3>
                {breakpoints.md && currentResult > 0 ? (
                    <ArrowLgButton
                        direction="left"
                        strokeColor="#f06623"
                        handleClick={handleClickPrev}
                        className={styles.prev}
                    />
                ) : null}
                <div className={styles.results}>
                    {visibleResults?.map((result) => renderProduct(result))}
                </div>
                {breakpoints.md &&
                currentResult + currentDisplayNumber < results.length - 1 ? (
                    <ArrowLgButton
                        direction="right"
                        strokeColor="#f06623"
                        handleClick={handleClickNext}
                        className={styles.next}
                    />
                ) : null}
            </div>
        </section>
    );
};

export default WhatSaunaResults;
