import React, { useContext } from 'react';
import { CapacityData } from '../../templates/StructuredDataContext';
import { ProductPagePreview } from '../Global/DataUtils/productQueries';
import { extractSpecificationsImage } from '../ProductSupportLayout/productSupportUtils';
import { PageMetadataContext } from '../../templates/PageMetaDataContext';
import WhatSaunaResults from './WhatSaunaResults';

interface Props {
    results_headline: { value: string };
    selectedWellnessTags: string[];
    selectedCapacity: string;
    allProducts: ProductPagePreview[];
}

interface Capacity {
    name?: string;
    codename?: string;
    value?: number;
}
interface WellnessType {
    codename?: string;
    name?: string;
    img?: string;
}

export interface ParsedSaunaProducts {
    name?: string;
    description?: string;
    wellnessTags?: {
        codename: string;
        name: string;
    }[];
    capacity?: {
        name?: string;
        codename?: string;
        value?: number;
    };
    image: { url: string; description: string; name: string };
    slug: string;
}

function parseProducts(
    allProducts: ProductPagePreview[],
    currentLanguage: string | undefined
): ParsedSaunaProducts[] {
    const products = [];

    for (let i = 0; i < allProducts.length; i++) {
        let product = allProducts[i];

        const wellnessTags =
            currentLanguage == 'DE-de'
                ? product?.elements?.wellness_type___de_de?.value
                : currentLanguage == 'FR-fr'
                ? product?.elements?.wellness_type___fr_fr?.value
                : product?.elements?.wellness_type?.value;

        if (wellnessTags.length) {
            const description =
                product?.elements?.page_components?.value?.filter(
                    (p) =>
                        p.__typename ===
                        'kontent_item_component___product_description'
                );
            const specs = product?.elements?.page_components?.value?.filter(
                (p) =>
                    p.__typename ===
                    'kontent_item_component___product_specifications'
            );
            const capacity = specs[0]?.elements?.specs?.value[0]?.elements
                ?.capacity?.value[0] as CapacityData;
            const image = extractSpecificationsImage(
                product?.elements?.page_components as any
            ) || { url: '', name: '', description: '' };

            products.push({
                name: product?.elements?.display_page_name?.value,
                description: description[0]?.elements?.short_description?.value,
                wellnessTags: wellnessTags,
                capacity: {
                    name: capacity?.elements?.[
                        currentLanguage == 'DE-de'
                            ? `capacity_tag___de_de`
                            : `capacity_tag`
                    ]?.value[0]?.name,
                    codename:
                        capacity?.elements?.capacity_tag?.value[0]?.codename,
                    value: capacity?.elements?.capacity_value?.value,
                },
                image: image,
                slug:
                    product?.elements?.slug?.url ||
                    product?.elements?.slug?.value ||
                    '',
            });
        }
    }

    return products;
}

const WhatSaunaResultsContainer: React.FC<Props> = ({
    allProducts,
    selectedCapacity,
    selectedWellnessTags,
    results_headline,
    ...props
}) => {
    const currentLanguage = useContext(PageMetadataContext)?.preferredLanguage;
    const parsedProducts = parseProducts(allProducts, currentLanguage);
    const matchedProducts = parsedProducts.filter((product) => {
        if (
            product?.capacity?.codename === selectedCapacity &&
            product?.wellnessTags?.length
        ) {
            for (let i = 0; i < product?.wellnessTags?.length; i++) {
                if (
                    selectedWellnessTags.includes(
                        product.wellnessTags[i].codename
                    )
                ) {
                    return true;
                }
            }
        }
        return false;
    });

    return (
        <WhatSaunaResults
            results={matchedProducts}
            results_headline={results_headline?.value}
            {...props}
        />
    );
};

export default WhatSaunaResultsContainer;
